<template>
  <valorem-modal
    ref="modal"
    titulo-modal=""
    @ok="salvar"
    :bloquear-fechar-backdrop="true"
    :bloquear-fechar-esc="true"
    esconder-cabecalho
    esconder-rodape
  >
    <div class="row mb-4">
      <div class="col-sm-6">
        <img
          src="../../assets/images/logo-valorem-pay.png"
          alt="homepage"
          class="dimensao-logo"
        />
      </div>
      <div class="col-sm-6 text-right" v-if="logo">
        <img :src="logo" class="mw-100 dimensao-logo" alt="Logo" />
      </div>
    </div>
    <div v-if="linkExpirado" class="">
      <div class="row">
        <div class="col-12 col-md-12 tag-link-expirado">
          {{ $t("CHECKOUT.LINK_EXPIRADO") }}
        </div>
        <div class="col-12 col-md-12 tag-link-expirado">
          {{ $t("CHECKOUT.LINK_EXPIRADO2") }}
        </div>
      </div>
    </div>
    <div class="">
      <div class="row">
        <input-text
          class="col-12 col-md-4"
          v-model="form.valor"
          :label="$t('CHECKOUT.VALOR')"
          ref="valor"
          required
          disabled
        />

        <input-select
          class="col-12 col-md-3"
          :options="opcoes.parcelas"
          v-model="form.numeroDeParcelas"
          :label="$t('CHECKOUT.TOTAL_PARCELAS')"
          ref="numeroDeParcelas"
          @input="handleTotalDeParcelasInput"
          :disabled="bloquearNumeroParcelas"
        ></input-select>

        <input-text
          v-if="exibirTaxas"
          class="col-12 col-md-5"
          v-model="form.valorTotalComTaxas"
          :label="$t('CHECKOUT.VALOR_TOTAL')"
          ref="valorTotalComTaxas"
          disabled
        />
        <input-text
          v-if="!exibirTaxas"
          class="col-12 col-md-5"
          v-model="form.valorTotalSemTaxas"
          :label="$t('CHECKOUT.VALOR_TOTAL')"
          ref="valorTotalSemTaxas"
          disabled
        />
        <input-hidden v-model="form.valorTotal" ref="valorTotal" />
        <input-hidden
          v-model="form.valorTotalComTaxas"
          ref="valorTotalComTaxas2"
        />
      </div>

      <div v-if="multiplosCartoes" class="row mt-1">
        <input-radio
          id="input-qtde-cartoes"
          class="col-12 col-md-6 mb-2"
          :label="$t('CHECKOUT.QUANTIDADE_CARTOES')"
          :options="opcoes.qtdeCartoes"
          v-model="form.qtdeCartoes"
          required
          @onChange="handleQuantidadeCartoesChange"
        />

        <div v-if="exibirTaxas && 1 == 2" class="col-12 col-md-6 tagTaxas">
          {{ $t(taxas) }}
        </div>
      </div>

      <div v-if="temComprador" class="">
        <div class="tagComprador label-comprador">
          {{ $t("CHECKOUT.COBRANCA") }}
        </div>
        <div class="row" id="comprador">
          <div class="col-12 col-md-12">
            <span class="tagComprador">{{ $t(nomeComprador) }}</span>
            <span v-if="documentoComprador" class="tagComprador"> | </span>
            <em v-if="documentoComprador" class="tagComprador">
              {{ $t(documentoComprador) }}
            </em>
            <span v-if="telefoneComprador" class="tagComprador">
              &nbsp;| {{ $t(telefoneComprador) }}</span
            >
          </div>
          <div v-if="temEndereco" class="col-12 col-md-12">
            <span class="tagComprador">{{ $t(endereco) }}</span>
          </div>
        </div>
      </div>

      <hr />
      <cartao-collapse
        ref="cartaoUm"
        numeroCartao="1"
        :ExibirNrCartoes="multiplosCartoes"
        :ExibirEndereco="exibirEndereco"
        :reduzir="false"
        :disableValor="cartoes.desabilitar.cartaoUm"
        :numeroParcelas="form.numeroDeParcelas || nrParcelas"
        @calcularValorDemaisCartoes="calcularValorDemaisCartoes"
        :PagamentoInternacional="pagamentoInternacional"
      />

      <cartao-collapse
        ref="cartaoDois"
        numeroCartao="2"
        :ExibirNrCartoes="multiplosCartoes"
        :ExibirEndereco="exibirEndereco"
        :reduzir="false"
        v-if="cartoes.mostrarTela.cartaoDois"
        :numeroParcelas="form.numeroDeParcelas || nrParcelas"
        @calcularValorDemaisCartoes="calcularValorDemaisCartoes"
        :PagamentoInternacional="pagamentoInternacional"
      />

      <cartao-collapse
        ref="cartaoTres"
        numeroCartao="3"
        :ExibirNrCartoes="multiplosCartoes"
        :ExibirEndereco="exibirEndereco"
        :reduzir="false"
        v-if="cartoes.mostrarTela.cartaoTres"
        :numeroParcelas="form.numeroDeParcelas || nrParcelas"
        @calcularValorDemaisCartoes="calcularValorDemaisCartoes"
        :PagamentoInternacional="pagamentoInternacional"
      />
      <hr />
    </div>
    <footer class="modal-footer pb-0 pr-0">
      <button
        type="button"
        v-if="!linkExpirado"
        class="btn btn-valorem-ciano"
        @click="salvar"
      >
        {{ $t("GERAL.CONFIRMAR") }}
      </button>
    </footer>
  </valorem-modal>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { InputText, InputSelect, InputRadio } from "@/components/inputs";
import { Checkout } from "@/common/models/Checkout";
import { AlertasMixin } from "@/common/mixins/AlertasMixin";
import CheckoutService from "@/common/services/checkout/checkout.service";
import CartaoCollapse from "./components/CartaoCollapse";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import Toast from "vue-toastification";
import Vue from "vue";
//import Confirmacao from "@/components/confirmacao/Confirmacao";

const options = {};
Vue.use(Toast, options);

export default {
  mixins: [AlertasMixin],
  name: "Index",
  components: {
    InputText,
    InputSelect,
    InputRadio,
    CartaoCollapse,
  },
  data() {
    return {
      form: new Checkout({}),
      enum: {
        qtdeCartoes: helpers.QuantidadeCartoes.map((x) => x.value),
      },
      opcoes: {
        parcelas: [],
        estados: helpers.Estados,
        meses: helpers.Meses,
        qtdeCartoes: helpers.QuantidadeCartoes,
      },
      cartoes: {
        mostrarTela: {
          cartaoDois: false,
          cartaoTres: false,
        },
        desabilitar: {
          cartaoUm: true,
        },
      },
      id: this.$route.params.id,
      logo: null,
      urlRedirecionamento: null,
      multiplosCartoes: false,
      bloquearNumeroParcelas: false,
      exibirEndereco: false,
      temComprador: false,
      temEndereco: false,
      nomeComprador: null,
      documentoComprador: null,
      telefoneComprador: null,
      endereco: null,
      nrParcelas: null,
      linkExpirado: null,
      pagamentoInternacional: false,
      exibirTaxas: false,
      exibirTelaFinalizacaoPagamento: false,
      taxas: null,
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.ASSINATURAS") },
    ]);

    // Um
    this.form.qtdeCartoes = helpers.QuantidadeCartoes[0].value;

    this.$refs.modal.abrirModal();
    this.buscar();
    // this.cartoes.valorParcela.cartaoUm = helpers.formatarValor(parcela.valorParcelaArredonda);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      CheckoutService.buscar(this.id)
        .then((res) => {
          this.logo = res.data.arquivoLogo;
          this.opcoes.parcelas = res.data.parcelas;
          this.urlRedirecionamento = res.data.exibirTelaFinalizacaoPagamento
            ? window.location.origin +
              "/aguardando-confirmacao?id=" +
              res.data.id
            : res.data.urlRedirecionamento;
          this.form.numeroDeParcelas = res.data.numeroDeParcelas;
          this.form.valor = res.data.valor;
          this.setarValorTotal(res.data.numeroDeParcelas);
          this.setarValorCartaoUm(res.data.numeroDeParcelas);
          this.setarValorTaxa(res.data.numeroDeParcelas);

          this.multiplosCartoes = res.data.multiplosCartoes;
          this.bloquearNumeroParcelas = res.data.bloquearNumeroParcelas;
          this.exibirEndereco = res.data.exibirEndereco;
          this.temComprador = res.data.temComprador;
          this.temEndereco = res.data.temEndereco;
          this.nrParcelas = res.data.numeroDeParcelas;
          this.linkExpirado = res.data.linkExpirado;
          this.pagamentoInternacional = res.data.pagamentoInternacional;
          this.form.plataforma = res.data.plataforma;
          this.exibirTaxas = res.data.exibirTaxas;
          this.exibirTelaFinalizacaoPagamento =
            res.data.exibirTelaFinalizacaoPagamento;
          if (res.data.dadosComprador) {
            this.nomeComprador = res.data.dadosComprador.nome;
            this.documentoComprador = helpers.formatarDocumento(
              res.data.dadosComprador.cpfcnpj
            );
            this.endereco = res.data.dadosComprador.endereco
              ? res.data.dadosComprador.endereco
              : null;
            this.telefoneComprador = res.data.dadosComprador.telefone
              ? helpers.maskTelefone(res.data.dadosComprador.telefone)
              : null;
          }
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.valor.valid());
      arValidation.push(this.$refs.numeroDeParcelas.valid());
      arValidation.push(this.$refs.cartaoUm.validarFormulario());

      if (this.form.qtdeCartoes == "Dois") {
        arValidation.push(this.$refs.cartaoDois.validarFormulario());
      } else if (this.form.qtdeCartoes == "Tres") {
        arValidation.push(this.$refs.cartaoDois.validarFormulario());
        arValidation.push(this.$refs.cartaoTres.validarFormulario());
      }

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    validarValoresSomaCartoes() {
      if (this.form.qtdeCartoes == "Um") return true;

      var numParcelas = this.form.numeroDeParcelas || this.nrParcelas;
      var somaValoresCartoes = helpers.removerConverterValor(
        this.$refs.cartaoUm.form.valor
      );

      if (this.form.qtdeCartoes == "Dois")
        somaValoresCartoes += helpers.removerConverterValor(
          this.$refs.cartaoDois.form.valor
        );

      if (this.form.qtdeCartoes == "Tres") {
        somaValoresCartoes += helpers.removerConverterValor(
          this.$refs.cartaoDois.form.valor
        );
        somaValoresCartoes += helpers.removerConverterValor(
          this.$refs.cartaoTres.form.valor
        );
      }

      let valorTotalDaParcela = this.opcoes.parcelas[numParcelas - 1].total;
      /*let diferenca =
        valorTotalDaParcela > somaValoresCartoes
          ? valorTotalDaParcela - somaValoresCartoes
          : somaValoresCartoes - valorTotalDaParcela;*/

      if (somaValoresCartoes.toFixed(2) != valorTotalDaParcela.toFixed(2)) {
        Vue.$toast.error(
          "A soma do 'Valor' de cada cartão, deve ser igual ao 'Valor Total'."
        );
        return false;
      }

      return true;
    },
    montarForm() {
      var formSalvar = cloneDeep(this.form);
      formSalvar.valor = helpers.removerValor(formSalvar.valor);
      formSalvar.valorTotal = helpers.removerValor(formSalvar.valorTotal);

      formSalvar.cartoes = [];
      formSalvar.cartoes.push(this.montarFormCartao(this.$refs.cartaoUm.form));

      if (this.form.qtdeCartoes == "Dois") {
        formSalvar.cartoes.push(
          this.montarFormCartao(this.$refs.cartaoDois.form)
        );
      } else if (this.form.qtdeCartoes == "Tres") {
        formSalvar.cartoes.push(
          this.montarFormCartao(this.$refs.cartaoDois.form)
        );
        formSalvar.cartoes.push(
          this.montarFormCartao(this.$refs.cartaoTres.form)
        );
      }

      return formSalvar;
    },
    montarFormCartao(formCartao) {
      var formCartaoSalvar = cloneDeep(formCartao);
      formCartaoSalvar.valor = helpers.removerValor(formCartaoSalvar.valor);
      formCartaoSalvar.numeroDoCartao =
        formCartaoSalvar.numeroDoCartao.replaceAll(" ", "");

      return formCartaoSalvar;
    },
    salvar() {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        if (!this.validarValoresSomaCartoes()) return false;
        let formSalvar = this.montarForm();

        this.$store.dispatch(START_LOADING);
        formSalvar.id = this.id;

        CheckoutService.salvar(formSalvar)
          .then((res) => {
            this.token = res.data.data.aggregateId;
            this.finalizar();
          })
          .catch((err) => {
            /*Confirmacao.Ok(
              this,
              null,
              this.$t("CHECKOUT.REINICIAR_PROCESSO")
            ).then(async (result) => {
              if (result.value) {
                this.$store.dispatch(START_LOADING);
                this.erroPagamento();
                this.$store.dispatch(STOP_LOADING);
              }
            });*/
            console.error("Failed to response: ", err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
        this.$emit("salvar", this.form);
      });
    },
    finalizar() {
      if (this.urlRedirecionamento)
        window.location.href = this.urlRedirecionamento;
      else this.alertaSucesso(this.$t("CHECKOUT.MENSAGEM_SUCESSO"), false);
    },
    setarValorTotal(numParcela) {
      let parcela = this.opcoes.parcelas[numParcela - 1];
      this.form.valorTotal = helpers.formatarValor(parcela.total);
      this.form.valorTotalSemTaxas = helpers.formatarValor(parcela.total);
      this.form.valorTotalComTaxas =
        parcela.taxas == 0
          ? `${helpers.formatarValor(parcela.total)}`
          : `${helpers.formatarValor(
              parcela.total
            )} Taxas ${helpers.formatarValor(parcela.taxas)}`;
    },
    setarValorCartaoUm(numParcela) {
      let parcela = this.opcoes.parcelas[numParcela - 1];
      this.$refs.cartaoUm.form.valor = helpers.formatarValor(parcela.total);
      // this.$refs.cartaoUm.form.valorParcela = helpers.formatarValor(parcela.valorParcelaArredondado);
    },
    setarValorTaxa(numParcela) {
      let parcela = this.opcoes.parcelas[numParcela - 1];
      this.taxas = parcela.taxas;
    },
    calcularValorDemaisCartoes(numeroCartao) {
      if (this.form.qtdeCartoes == "Dois") {
        this.calcularValorDemaisCartoesDoisCartoes(numeroCartao);
      } else if (this.form.qtdeCartoes == "Tres") {
        this.calcularValorDemaisCartoesTresCartoes(numeroCartao);
      }
    },
    calcularValorDemaisCartoesTresCartoes(numeroCartao) {
      let valorTotal = helpers.removerConverterValor(this.form.valorTotal);

      if (
        (numeroCartao == 1 || numeroCartao == 2) &&
        this.$refs.cartaoUm.form.valor &&
        this.$refs.cartaoUm.form.valor != 0 &&
        this.$refs.cartaoDois.form.valor &&
        this.$refs.cartaoDois.form.valor != 0
      ) {
        let somaCartaoUmDois = helpers.removerConverterValor(
          this.$refs.cartaoUm.form.valor
        );
        somaCartaoUmDois += helpers.removerConverterValor(
          this.$refs.cartaoDois.form.valor
        );

        if (valorTotal > somaCartaoUmDois) {
          this.$refs.cartaoTres.form.valor = helpers.formatarValor(
            valorTotal - somaCartaoUmDois
          );
          return;
        }
      } else if (
        (numeroCartao == 1 || numeroCartao == 3) &&
        this.$refs.cartaoUm.form.valor &&
        this.$refs.cartaoUm.form.valor != 0 &&
        this.$refs.cartaoTres.form.valor &&
        this.$refs.cartaoTres.form.valor != 0
      ) {
        let somaCartaoUmTres = helpers.removerConverterValor(
          this.$refs.cartaoUm.form.valor
        );
        somaCartaoUmTres += helpers.removerConverterValor(
          this.$refs.cartaoTres.form.valor
        );

        if (valorTotal > somaCartaoUmTres) {
          this.$refs.cartaoDois.form.valor = helpers.formatarValor(
            valorTotal - somaCartaoUmTres
          );
          return;
        }
      } else if (
        (numeroCartao == 2 || numeroCartao == 3) &&
        this.$refs.cartaoDois.form.valor &&
        this.$refs.cartaoDois.form.valor != 0 &&
        this.$refs.cartaoTres.form.valor &&
        this.$refs.cartaoTres.form.valor != 0
      ) {
        let somaCartaoDoisTres = helpers.removerConverterValor(
          this.$refs.cartaoDois.form.valor
        );
        somaCartaoDoisTres += helpers.removerConverterValor(
          this.$refs.cartaoTres.form.valor
        );

        if (valorTotal > somaCartaoDoisTres) {
          this.$refs.cartaoUm.form.valor = helpers.formatarValor(
            valorTotal - somaCartaoDoisTres
          );
          return;
        }
      }
    },
    calcularValorDemaisCartoesDoisCartoes(numeroCartao) {
      let valorTotal = helpers.removerConverterValor(this.form.valorTotal);
      if (
        numeroCartao == 1 &&
        this.$refs.cartaoUm.form.valor &&
        this.$refs.cartaoUm.form.valor != 0
      ) {
        let valorCartaoUm = helpers.removerConverterValor(
          this.$refs.cartaoUm.form.valor
        );
        if (valorTotal > valorCartaoUm)
          this.$refs.cartaoDois.form.valor = helpers.formatarValor(
            valorTotal - valorCartaoUm
          );
      }

      if (
        numeroCartao == 2 &&
        this.$refs.cartaoDois.form.valor &&
        this.$refs.cartaoDois.form.valor != 0
      ) {
        let valorCartaoDois = helpers.removerConverterValor(
          this.$refs.cartaoDois.form.valor
        );
        if (valorTotal > valorCartaoDois)
          this.$refs.cartaoUm.form.valor = helpers.formatarValor(
            valorTotal - valorCartaoDois
          );
      }
    },
    handleTotalDeParcelasInput(numParcela) {
      this.setarValorTotal(numParcela);
      this.setarValorTaxa(numParcela);

      if (this.form.qtdeCartoes == "Um") {
        this.setarValorCartaoUm(numParcela);
      } else {
        this.calcularValorDemaisCartoes(1);
      }

      // this.validarValoresSomaCartoes();
    },
    handleQuantidadeCartoesChange() {
      this.cartoes.desabilitar.cartaoUm = false;

      if (this.form.qtdeCartoes == "Um") {
        this.cartoes.mostrarTela.cartaoDois = false;
        this.cartoes.mostrarTela.cartaoTres = false;
        this.cartoes.desabilitar.cartaoUm = true;
        this.setarValorCartaoUm(this.form.numeroDeParcelas || this.nrParcelas);
      } else if (this.form.qtdeCartoes == "Dois") {
        this.cartoes.mostrarTela.cartaoDois = true;
        this.cartoes.mostrarTela.cartaoTres = false;
      } else if (this.form.qtdeCartoes == "Tres") {
        this.cartoes.mostrarTela.cartaoDois = true;
        this.cartoes.mostrarTela.cartaoTres = true;
      }
    },
    /*erroPagamento() {
      const nextURL = window.location.origin + "/checkout-erro";
      window.location.href = nextURL;
    },*/
    aguardandoConfirmacao() {
      const nextURL = window.location.origin + "/aguardando-confirmacao";
      window.location.href = nextURL;
    },
  },
};
</script>

<style>
.modal-dialog {
  max-width: 720px !important;
}
.dimensao-logo {
  max-width: 106px;
  max-height: 41px;
}
.tagComprador {
  color: rgb(130, 130, 130) !important;
  font-size: 0.8rem !important;
}
.tag-link-expirado {
  color: darkred;
  font-size: 1.2rem !important;
  text-align: center;
  font-weight: bold;
}
.tagTaxas {
  color: rgb(255, 106, 106) !important;
  font-size: 0.8rem !important;
  text-align: right;
}
</style>